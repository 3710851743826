import React, { Component } from 'react';
import { StaticQuery, Link, graphql } from 'gatsby';
import { Trans, withI18n } from '@lingui/react';
import { prefix } from '../i18n-config';

// import Img from 'gatsby-image';
import Logo from './img/Logo';

class NavPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeNav: 'about',
      liHover: '',
      hasScrolled: true,
      scrollY: 0,
      isOpen: false,
      isClicked: false,
      headerHeight: 98,
      timeout: null
    };

    this.Pos = '';
    this.scrollContainer = null;

    this.handleClick = this.handleClick.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    const { hasScrolled, liHover } = this.state;
    this.scrollContainer = document.querySelector('.main-wrapper');
    this.aboutSection = document.getElementById('about');
    this.musicSection = document.getElementById('music');
    this.storeSection = document.getElementById('store');

    window.addEventListener('scroll', this.handleScroll);

    this.handleScroll();
    setInterval(() => {
      if (hasScrolled) {
        this.fixNavOnScroll();
      }
    }, 150);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleToggleClick(e) {
    const { isOpen } = this.state;
    this.setState({isOpen : !isOpen});
  }

  handleClick(e) {
    this.handleEnter(e);

    const target = e.target.dataset.target;
    const targetElem = document.querySelector(`#${target}`);

    if (targetElem) {
      e.preventDefault();

      targetElem.scrollIntoView({
        behavior: 'smooth',
      });
    }

    this.setState({
      activeNav: target,
      isClicked: true
    });
  }

  handleEnter(e) {
    const target = e.target.dataset.target;

    this.setState({
      liHover: target,
    });
  }

  handleLeave(e) {
    const { activeNav } = this.state;

    this.setState({
      liHover: activeNav
    });
  }

  handleScroll(e) {
    const { timeout } = this.state;
    const scrollTop = window.scrollY;
    const height = Math.max(80, 98 - scrollTop);

    if (timeout) {
      clearTimeout(timeout);
    }

    this.setState({
      hasScrolled: true,
      headerHeight: height,
      headerBg: height === 80,
      timeout: setTimeout(() => {
        this.setState({ isClicked: false })
      }, 1000)
    });

    this.checkSectionPosition();
  }

  checkSectionPosition() {
    const { liHover, isClicked } = this.state;
    const { pathName } = this.props;

    if (isClicked || pathName !== '/') {
      return;
    }

    let activeSection = '';
    const currentPos = window.scrollY;
    const viewportHeight = window.innerHeight;
    const visibleArea = currentPos + (viewportHeight/2);

    const aboutRect = this.aboutSection.getBoundingClientRect();
    const musicRect = this.musicSection.getBoundingClientRect();
    const storeRect = this.storeSection.getBoundingClientRect();

    if (storeRect.top + currentPos < visibleArea) {
      activeSection = 'store';
    } else if (musicRect.top + currentPos < visibleArea) {
      activeSection = 'music';
    } else if (aboutRect.top + currentPos < visibleArea) {
      activeSection = 'about';
    }

    if (activeSection !== liHover) {
      this.setState({ liHover: activeSection });
    }
  }

  fixNavOnScroll() {
    const { scrollY, isOpen } = this.state;
    const currentPos = window.scrollY;

    if (Math.abs(scrollY - currentPos) <= 20) {
      return;
    }
    // if (scrollY > 0 && currentPos > scrollY) {
    //   // Scroll Down
    //   this.setState({ navUp: true });
    // } else {
    //   // Scroll Up
    //   this.setState({ navUp: false });
    // }
    if (currentPos > 100) {
      this.setState({ headerBg: true });
    } else {
      this.setState({ headerBg: false });
    }
    this.setState({
      hasScrolled: false,
      scrollY: currentPos,
    });
  }

  render() {
    const {
      lang, location, pathName
    } = this.props;
    const { navUp, liHover, headerBg, isOpen, activeNav } = this.state;
    const langPrefix = prefix(lang);

    return (
      <header
        className={`main-header ${
          navUp ? 'main-header--hidden' : 'main-header--visible'
        } ${headerBg ? 'main-header--bg' : ''}`}
        style={{height: this.state.headerHeight+'px'}}
      >
        <div className="container">
          <Link
            to={`${langPrefix}/`}
            className="main-header__logo-wrapper"
            onClick={(e) => {
              this.handleClick(e);
            }}
          >
            <Logo className='main-header__logo'/>
          </Link>

          <div className={`main-menu ${!liHover && pathName === '/' ? 'no-active-item' : ''}`}>
            <button
              type="button"
              className={`main-menu__toggle ${isOpen ? 'toggled' : ''}`}
              onClick={() => {
                this.handleToggleClick();
              }}
            >
              <ul className="lines">
                <li className="line"></li>
                <li className="line"></li>
              </ul>
            </button>

            <div className={`main-menu__list ${isOpen ? 'toggled' : ''}`}>
              <div
                className={`main-menu__list__item ${liHover === 'about' ? 'active' : ''} ${
                  pathName.includes('about') ? 'active' : ''
                }`}
              >
                <Link
                  to={`${langPrefix}/#about`}
                  data-target="about"
                  onClick={(e) => this.handleClick(e)}
                  onMouseEnter={e => this.handleEnter(e)}
                  onMouseLeave={e => this.handleLeave(e)}
                >
                  <Trans>About</Trans>
                </Link>
              </div>

              <div
                className={`main-menu__list__item ${liHover === 'music' ? 'active' : ''} ${
                  pathName.includes('music') ? 'active' : ''
                }`}
              >
                <Link
                  to={`${langPrefix}/#music`}
                  data-target="music"
                  onClick={(e) => this.handleClick(e)}
                  onMouseEnter={e => this.handleEnter(e)}
                  onMouseLeave={e => this.handleLeave(e)}
                >
                  <Trans>Music</Trans>
                </Link>
              </div>

              <div className='logo-wrapper'>
                <Link
                  to={`${langPrefix}/`}
                  onClick={(e) => this.handleClick(e)}
                >
                  <Logo className='main-header__logo'/>
                </Link>
              </div>

              <div
                className={`main-menu__list__item  ${liHover === 'store' ? 'active' : ''} ${
                  pathName.includes('store') ? 'active' : ''
                }`}
              >
                <Link
                  data-target="store"
                  to={`/#store`}
                  onClick={(e) => this.handleClick(e)}
                  onMouseEnter={e => this.handleEnter(e)}
                  onMouseLeave={e => this.handleLeave(e)}
                >
                  <Trans>Store</Trans>
                </Link>
              </div>

              <div
                className={`main-menu__list__item  ${liHover === 'social' ? 'active' : ''} ${
                  pathName.includes('social') ? 'active' : ''
                }`}
              >
                <Link
                  to={`/#social`}
                  data-target="social"
                  onClick={(e) => this.handleClick(e)}
                  onMouseEnter={e => this.handleEnter(e)}
                  onMouseLeave={e => this.handleLeave(e)}
                >
                  <Trans>Social</Trans>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}       
export default withI18n()(NavPanel);