import React, { Component } from 'react';
import { Link } from 'gatsby';

class Button extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonStyle: {}
    };
  }

  handleMouseMove(event) {
    const rect = this.buttonRef.getBoundingClientRect();
    const width = this.buttonRef.clientWidth;
    const height = this.buttonRef.clientHeight;
    const posX = event.clientX - rect.x;
    const posY = event.clientY - rect.y;
    const mouseXpercentage = Math.round(posX / width * 100);
    const mouseYpercentage = Math.round(posY / height * 100);

    // const buttonStyle = {
    //   background: `radial-gradient(circle at ${mouseXpercentage}% ${mouseYpercentage}%, rgba(255,255,255,0.2), transparent 50%)`
    // }

    const radians = Math.atan2(posX, posY);
    const degree = (radians * (180 / Math.PI) * -1) + 180;

    const buttonStyle = {
      borderImage: `linear-gradient(${degree}deg, transparent, #fff 75%, #fff 75%, #fff 1%) 1`,
      background: `radial-gradient(circle at ${mouseXpercentage}% ${mouseYpercentage}%, rgba(255,255,255,0.2), transparent 50%)`
    }

    this.setState({buttonStyle})
  }

  handleMouseLeave() {
    this.setState({buttonStyle: {}})
  }

  handleMouseHover(event) {
    const rect = this.buttonRef.getBoundingClientRect();
    const width = this.buttonRef.clientWidth;
    const height = this.buttonRef.clientHeight;
    const posX = event.pageX - rect.x;
    const posY = event.pageY - rect.y;
    const mouseXpercentage = Math.round(posX / width * 100);
    const mouseYpercentage = Math.round(posY / height * 100);

    // const buttonStyle = {
    //   background: `radial-gradient(circle at ${mouseXpercentage}% ${mouseYpercentage}%, rgba(255,255,255,0.2), transparent 50%)`
    // }

    const radians = Math.atan2(posX, posY);
    const degree = (radians * 180)/Math.PI;//radians*(180/Math.PI);//(radians * (180 / Math.PI) * -1) + 180;
    // this.setState({buttonStyle})
  }

  handleClick(e) {
    if (this.props.onClick) {
      e.preventDefault();

      this.props.onClick()
    }
  }

  render() {
    const {
      className, id, children, href, target
    } = this.props;
    const {buttonStyle} = this.state;
    const isInternalPage = /^\/(?!\/)/.test(href);

    return (
      isInternalPage ?
        <Link id={id}
          to={href}
          className={`button button--custom ${className ? className : ''}`}
          onMouseMove={(e) => this.handleMouseMove(e)}
          onMouseLeave={(e) => this.handleMouseLeave(e)}
          onMouseEnter={(e) => this.handleMouseHover(e)}
          onClick={(e) => this.handleClick(e)}
        >
          <span className="wrapper" ref={(ref) => (this.buttonRef = ref)} style={buttonStyle} >
            {children}
          </span>
        </Link>
      :
        <a id={id}
          href={href}
          target={target}
          className={`button button--custom ${className ? className : ''}`}
          onMouseMove={(e) => this.handleMouseMove(e)}
          onMouseLeave={(e) => this.handleMouseLeave(e)}
          onMouseEnter={(e) => this.handleMouseHover(e)}
          onClick={(e) => this.handleClick(e)}
        >
          <span className="wrapper" ref={(ref) => (this.buttonRef = ref)} style={buttonStyle} >
            {children}
          </span>
        </a>
    );
  }
}

export default Button;

