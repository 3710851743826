import React, { Component, Children, useEffect, useState, useRef } from 'react';
import { withI18n } from '@lingui/react';
import { Link, navigate } from 'gatsby';
import { map, findIndex } from 'lodash';
import classNames from 'classnames';
import FilterArrow from './img/FilterArrow';

function Dropdown(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState(props.title);
  const [activeIndex, setActiveIndex] = useState();

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  useEffect(() => {
    setTitle(props.title);
  }, [props.title]);

  const handleToggle = (e) => {
    setIsOpen(!isOpen);
  }

  const handleMousehover = (e) => {
    if (!props.showOnToggle) {
      setIsOpen(true);
    }
  }

  const handleMouseLeave = (e) => {
    if (!props.showOnToggle) {
      setIsOpen(false);
    }
  }

  const handleItemClick = (i) => {
    if (activeIndex !== i) {
      setActiveIndex(i);
    } else {
      setActiveIndex(null);
    }
  }

  return (
    <div className={`dropdown ${props.className} ${props.showFilterIcon ? 'filter' : ''} ${isOpen ? 'open' : ''}`} onClick={props.showOnToggle ? handleToggle : null}  onMouseOver={handleMousehover} onMouseLeave={handleMouseLeave}>
      <span className="title">{title} {props.showFilterIcon && <FilterArrow />}</span>
      {isOpen && <div className="wrapper">
        {map(props.children, (child, i) => {
          return <div className={classNames('item', activeIndex === i ? 'active' : '')} key={i} onClick={() => handleItemClick(i)}>{child}</div>
        })}
      </div>}
    </div>
  );
}

export default withI18n()(Dropdown);

