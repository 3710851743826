import React, { useEffect, useState } from 'react';
import { TransitionGroup, Transition as ReactTransition } from 'react-transition-group';
import getTransitionStyle from '../helpers/getTransitionStyle';

const Transition = (props) => {
  const { children, location } = props;
  const [ duration, setDuration ] = useState(300);

  // useEffect(() => {
  //   if (location.pathname.indexOf('/games/lifecycle') > -1 || location.pathname.indexOf('/news') > -1) {
  //     setDuration(0);
  //   }
  // }, []);

  return (
    <TransitionGroup>
      <ReactTransition
        appear
        key={location.pathname}
        timeout={{
          enter: duration,
          exit: duration
        }}
      >
        {(status) => {
          return (
            <div
              style={
                {
                  ...getTransitionStyle({
                    status: status,
                    timeout: duration,
                  }),
                }
              }
              className={
                `fade fade-${status}`
              }
            >
              {children}
            </div>
          );
        }}
      </ReactTransition>
    </TransitionGroup>
  );
}

export default Transition;
