import React from "react";

const FilterArrow = props => (
  <svg className="arrow-icon" width={19} height={19} viewBox="0 0 19 19" fill="none" {...props}>
    <g clipPath="url(#clip0_37_153)">
      <path d="M7.91667 14.25H11.0833V12.6667H7.91667V14.25ZM2.375 4.75V6.33333H16.625V4.75H2.375ZM4.75 10.2917H14.25V8.70833H4.75V10.2917Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_37_153">
        <rect width="19" height="19" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export default FilterArrow;

